import type { StackProps } from "@chakra-ui/react";
import { Box, HStack, useToken } from "@chakra-ui/react";
import React from "react";

import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Wordmark } from "../assets/wordmark.svg";

type LogoTitleProps = {
  size?: number;
} & StackProps;

export function LogoTitle({ size = 10, ...props }: LogoTitleProps) {
  const height = useToken("space", size);
  return (
    <HStack spacing={`calc(${height} / 5)`} {...props}>
      <Box as={Logo} h={height} />
      <Box as={Wordmark} h={height} fill="current" title="Knoword" role="img" />
    </HStack>
  );
}
